import React from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'

const InputField = ({ name, type, width, height, background, placeholder }) => {

    return (
        <input className={style.input} placeholder={placeholder} style={{ width: width ? `${width}rem` : null, height: height ? `${height}rem` : null, background: background ? `${background}` : null }}>
        </input>
    )
}

InputField.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
    background: PropTypes.string,
    placeholder: PropTypes.string
}

InputField.defaultProps = {
    width: null,
    height: null,
    name: null,
    background: null,
    placeholder: null,
    type: 'text'
}

export default InputField