import React from 'react'
import { connect } from 'react-redux'
import { updateLocationCell } from '../../state/actions/index'
import PropTypes from 'prop-types'
import style from './style.module.scss'

const LocationCell = ({ data, id, isHovered, dispatch }) => {

    let { name = 'N/A', address = '', phone = null, hours = '', coords = {} } = data

    const onMouseEnter = () => {
        dispatch(updateLocationCell(id))
    }

    const onClick = (coords) => {
        window.open(`https://www.google.com/maps/search/?api=1&query=${coords.lat}, ${coords.long}`, '_blank');
    }

    return (
        <div onClick={() => onClick(coords)}
            onMouseEnter={onMouseEnter}
            className={`${style.cellWrapper} ${isHovered ? style.hovered : null}`}>
            <div className={style.info}>
                <h1 > {name} </h1>
                <p> {address} </p>
                <p> {phone} </p>
                <p> {hours} </p>
            </div>
            <div className={style.directionAction} >
                <i className={`${style.icon} material-icons`} > gps_fixed </i>
            </div>
        </div>
    )
}

LocationCell.propTypes = {
    header: PropTypes.string,
    address: PropTypes.string,
    phone: PropTypes.string,
    hours: PropTypes.string
}

LocationCell.defaultProps = {
    header: null,
    address: null,
    phone: null,
    hours: null
}

export default connect((state, props) => {
    return {
        isHovered: state.hoveredCell == props.id ? true : false
    }
})(LocationCell)