import React from 'react'
import style from './style.module.scss'
import LocationSearch from '../LocationSearch'
import LocationList from '../LocationList'
import { GoogleMap } from '../'

const locations = [
    {
        name: 'Aguada',
        phone: '787.945.7352',
        address: 'Barrio Malpaso, Carr, 417 Km. 2.1 Aguada, PR 00601',
        coords: {
            lat: 18.373870,
            long: -67.168211
        }
    },
    {
        name: 'Aguadilla',
        phone: '787.891.3945',
        address: 'Carr. Núm. 2, Km. 118.2 Bo. Ceiba Baja Aguadilla, PR 00605',
        coords: {
            lat: 18.454635,
            long: -67.082734
        }
    },
    {
        name: 'Carolina',
        phone: '787.757.7200',
        address: `Ave. 65 de Inf., Km. 11 Intersección Carr. Núm. 853 Entrada Barrazas
        Carolina, PR 00987`,
        coords: {
            lat: 18.375223,
            long: -65.949592
        }
    },
    {
        name: 'Lares',
        phone: '787.563.0302',
        address: `Bo. Callejones Carr. 129, Km. 19.5 Lares, PR 00669`,
        coords: {
            lat: 18.342784,
            long: -66.836113
        }
    },
    {
        name: 'Moca',
        phone: '787.877.9000',
        address: 'Carr. 111, Km. 11.3 Barrio Capá Moca, PR',
        coords: {
            lat: 18.366629,
            long: -67.055677
        }
    },
    {
        name: 'Ponce',
        phone: '787.813.0500',
        address: 'Bo. Calzada Carr. Núm. 1, Km. 121.5 Frente Aeropuerto Mercedita Ponce, PR 00731',
        coords: {
            lat: 18.006727,
            long: -66.562326
        }
    },
    {
        name: 'Toa Baja',
        phone: '787.251.8300',
        address: 'Bo. Candelaria Carr. Núm. 2, Km. 20.1 Toa Baja, PR 00949',
        coords: {
            lat: 18.410333,
            long: -66.234538
        }
    }
]

const LocationMap = ({ }) => {

    return (
        <div className={style.container}>
            <div className={style.locationWrapper}>
                <LocationSearch />
                <LocationList locations={locations} />
            </div>
            <div className={style.map}>
                <GoogleMap locations={locations} />
            </div>
        </div>
    )
}

export default LocationMap;