import React from 'react'
import { connect } from 'react-redux';
import style from './style.module.scss'
import PropTypes from 'prop-types';
import LocationCell from '../LocationCell'
import Scrollable from '../Scrollable'
import { toggleContestDetails } from '../../state/actions';

const LocationList = (props) => {

    return (
        <div className={style.locationListWrapper}>
            <Scrollable>
                {
                    props.locations.map((item, key) => <LocationCell key={key} id={key} data={item} />)
                }
            </Scrollable>
            <div className={style.banner} onClick={(e) => props.dispatch(toggleContestDetails(true))}>
                <span className={style.copy}>Reglas del Sorteo “Tiendita a Tu Lado”</span>
                <button className={style.learnMore}>Aprende más</button>
            </div>
        </div>
    )
}

LocationList.propTypes = {
    locations: PropTypes.array
}

LocationList.defaultProps = {
    locations: []
}


export default connect()(LocationList);