import React from 'react'
import style from './style.module.scss'
import InputField from '../InputField'

const LocationMap = ({ }) => {


    return (
        <div className={style.searchWrapper}>
            <h1>Tienditas Holsum</h1>
            {/* Todo: Need a call to action button */}
            {/* <form className={style.form}>
                <input placeholder="Search locations..." />
            </form> */}
        </div>
    )
}



export default LocationMap